.centered-item-at-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100vh;
}

.screen-state-label {
    margin-top: 10px;
    text-align: center;
}

@keyframes ldio-9x2y98ulabc {
    0% {
        transform: scale(1.1500000000000001);
    }
    100% {
        transform: scale(1);
    }
}

.ldio-9x2y98ulabc div {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 26.66666666666667px;
    left: 26.66666666666667px;
    background: #fc4774;
    animation: ldio-9x2y98ulabc 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.3s;
}

.ldio-9x2y98ulabc div:nth-child(2) {
    top: 26.66666666666667px;
    left: 113.33333333333334px;
    background: #28a745;
    animation-delay: -0.2s;
}

.ldio-9x2y98ulabc div:nth-child(3) {
    top: 113.33333333333334px;
    left: 26.66666666666667px;
    background: #5a67d8;
    animation-delay: 0s;
}

.ldio-9x2y98ulabc div:nth-child(4) {
    top: 113.33333333333334px;
    left: 113.33333333333334px;
    background: #ffc107;
    animation-delay: -0.1s;
}

.loadingio-spinner-cube-4h8q9oewrtf {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.ldio-9x2y98ulabc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-9x2y98ulabc div {
    box-sizing: content-box;
}

.book-box-text {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.add-book-box {
    font-size: 50px;
    background-color: whitesmoke;
    color: darkslategray;
    border-color: darkslategray;
    border-style: solid;
    border-width: 5px;
    margin-top: 10px;
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.full-span {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emtpy-full-span {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.full-span-start {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.add-chapters {
    font-size: 30px;
    text-align: center;
    padding: 0 10px;
}

.book-img {
    width: 100%;
    max-height: 400px;
}

.bookImg {
    width: 128px;
}

.book-box {
    font-size: 14px;
    background-color: whitesmoke;
    color: darkslategray;
    border-color: darkslategray;
    border-style: solid;
    border-width: 5px;
    width: 100%;
    height: 700px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
}

.question-card {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #e2e8f0;
    margin: 15px auto;
    width: 360px;
    height: 100%;
    overflow-wrap: break-word;
    white-space: unset;
}

.question-real-time-card {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 360px;
    height: 100%;
    overflow-wrap: break-word;
    white-space: unset;
}

.sub-question-card {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #e2e8f0;
    margin: 15px auto;
    width: 300px;
    height: auto;
    overflow-wrap: break-word;
    white-space: unset;
}

.question-card .katex,
.sub-question-card .katex {
    white-space: unset !important;
    font-size: 1em !important;
}

.question_part_div {
    margin-bottom: 2px;
    height: max-content;
}

.question-container {
    padding-left: 30px;
    padding-right: 30px;
    color: #864766;
    font-size: 20px;
    font-family: HelveticaNeue;
    text-align: left;
    padding-bottom: 15px;
}

.question-option {
    align-self: stretch;
    display: flex;
    background-color: #ffffff;
    color: #864766;
    border-radius: 8px;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 30px;
    margin-bottom: 10px;
    border: 1px solid #864766;
    flex: 1 0 auto;
}

.option-identifier-box {
    background-color: #ffffff;
    height: 26px;
    width: 26px;
    max-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #864766;
    font-family: HelveticaNeue-Bold;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #864766;
}

.option-identifier-box-selected {
    background-color: #270f36;
    height: 26px;
    width: 26px;
    max-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: HelveticaNeue-Bold;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    flex: 1 0 auto;
}

.option-text {
    margin-left: 10px;
    font-size: 18px;
    color: #864766;
    font-family: HelveticaNeue;
    width: -webkit-calc(100% - 36px);
    width: -moz-calc(100% - 36px);
    width: calc(100% - 36px);
}

.question-img {
    align-self: stretch;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    object-fit: contain;
    width: 100%;
}

.oIcon-container {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 18px;
    height: 18px;
}

.oIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.question-option-selected {
    align-self: stretch;
    display: flex;
    background-color: #f09f9c;
    color: #270f36;
    border-radius: 8px;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 30px;
    margin-bottom: 10px;
    border: 1px solid #270f36;
    flex: 1 0 auto;
    position: relative;
}

.question-option-correct {
    border-color: #1e8e3e !important;
    background-color: #e6f4ea !important;
}

.question-option-incorrect {
    border-color: #b70000 !important;
    background-color: #ffc5bf !important;
}

.answeredImageContainer {
    position: relative;
    margin: 10px auto 25px auto;
    width: 100%;
    height: auto;
}

.img-contain {
    width: 100%;
    object-fit: contain;
}

.feedbackImageMaps {
    position: absolute;
    background-color: #01c720;
    width: 36px;
    height: 36px;
    color: white;
    text-align: center;
    font-size: 22px;
    font-family: montserrat-medium;
    border-radius: 100%;
}

.qIcon-container {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.qIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.correct-answer-span {
    color: #6f6e6e;
    font-size: 16px;
    line-height: 22px;
    font-family: montserrat-bold;
    font-weight: bold;
    margin-bottom: 4px;
}

.not-corrected-watermark {
    background-color: #6c6371;
    padding: 0 10px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    font-size: 10px;
    line-height: 10px;
    color: white;
    font-family: montserrat-regular;
    text-align: center;
}

.tagsContainer {
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.qTag {
    background-color: rgba(240, 159, 156, 0.7);
    padding: 0px 10px;
    color: #270f36;
    font-size: 12px;
    font-weight: 500;
    font-family: montserrat-medium;
    margin: 10px 4px 0 4px;
    border-radius: 12px;
    height: 24px;
    line-height: 24px;
}

.tag {
    padding: 0px 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: montserrat-medium;
    margin: 10px 4px 0 4px;
    border-radius: 12px;
    height: 24px;
    line-height: 24px;
}

.img-contain2 {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.solutionContainer {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.solutionDivider {
    border: 0;
    border-top: 1px solid #6f6e6e;
    margin: 0 16px;
}

.solutionToggleHolder {
    height: 56px;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.solutionToggleText {
    font-size: 14px;
    color: #555353;
    font-family: montserrat-semibold;
    font-weight: 600;
}

.htmlParentDiv {
    background-color: white;
    padding: 0 12px;
    max-width: inherit;
    overflow-x: auto;
    margin-bottom: 22px;
}

.htmlQuestionContainer {
    max-width: inherit;
    overflow-x: auto;
}

.badge-yellow {
    display: inline;
    padding: 4px 7px;
    background-color: rgba(245, 160, 20, 0.66);
    color: #270f36;
    font-size: 12px;
    font-family: montserrat-medium;
    border-radius: 6px;
    line-height: 15px;
    margin-right: auto;
}

.badge-holder {
    display: flex;
    flex-direction: row;
    padding-left: 18px;
    margin-bottom: 16px;
}

.modalDialog {
    overflow-x: auto;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    background: rgba(0, 0, 0, 0.5);
}

.commentDialog {
    background: none !important;
    overflow-y: hidden !important;
}

.modalDocument {
    display: flex;
    align-items: center;
    min-height: calc(100% - 4rem);
    position: relative;
    width: auto;
    margin: 0.5rem;
}

.modalDocumentExpanded {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    position: relative;
    width: auto;
    margin: 0.5rem;
}

.commentPopup {
    width: 260px;
    height: auto;
    border-radius: 4px;
    background-color: white;
    border: 0.5px solid #707070;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    flex-direction: column;
    display: flex;
    margin: auto;
    pointer-events: auto;
}

.commentPopupHeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
}

.commentHeaderRowCombiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.roundedSpan {
    width: 36px;
    height: 36px;
    color: white;
    text-align: center;
    font-size: 22px;
    font-family: montserrat-medium;
    border-radius: 100%;
    background-color: #ba4bd0;
}

.commentHeaderText {
    color: #6f6e6e;
    font-size: 16px;
    line-height: 24px;
    font-family: montserrat-medium;
    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.commentBodyText {
    color: #555353;
    font-size: 16px;
    line-height: 24px;
    font-family: montserrat-medium;
    font-weight: 400;
}

.commentPopupText {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 5px;
    margin-bottom: 40px;
}

.right-15 {
    right: 15px;
}

.top-5 {
    top: 5px;
}

.right-5 {
    right: 5px;
}

.right-35 {
    right: 35px;
}

.right-40 {
    right: 40px;
}

.right-45 {
    right: 45px;
}

.top-45 {
    top: 45px;
}

.top-38 {
    top: 38px;
}

.top-100 {
    top: 100%;
}

.max-h-25px {
    max-height: 25px;
}

.w-r-10 {
    width: calc(100% - 40px);
}

.w-280px {
    width: 280px;
}

.h-300px {
    height: 300px;
}

.h-280px {
    height: 280px;
}

.text-deleted-light {
    color: rgba(130, 130, 130, 1);
}

.white-shadow {
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.16);
}

.shadow-bg-dim {
    background: rgba(0, 0, 0, 0.5);
}

.Dnd {
    text-align: left;
}

.Dnd-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
    padding: 0 6px;
}

.Dnd-link {
    color: #61dafb;
}

@keyframes Dnd-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.characters {
    list-style: none;
    padding-left: 0;
}

.book-card {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 5px 8px 5px 8px;
    margin-bottom: 18px;
    min-width: 280px;
    height: 90px;
    background-color: #f7fbff;
    color: #270f36;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
}

.book-card-text {
    font-size: 18px;
    font-family: HelveticaNeue;
    font-weight: 500;
}

.book-card-img {
    height: 80px;
    width: 60px;
    margin-right: 0.5em;
}

.scrollbar-hidden::-webkit-scrollbar{
    display: none;
}